import React from 'react';
import styled, { keyframes } from 'styled-components';

const Logo = ({ className }) => {
    return (
        <Root className={ className }>
            <svg xmlns="http://www.w3.org/2000/svg" width="1771" height="670" viewBox="0 0 1771 670">
                <g id="Web_1920_1" data-name="Web 1920 – 1" clip-path="url(#clip-Web_1920_1)">
                    <g id="Group_2" data-name="Group 2">
                        <g id="Group_1" data-name="Group 1" transform="translate(0 -207)">
                            <g id="logo" transform="translate(0 204.392)">
                                <g id="Web_1920_3" transform="translate(-21.03 -1.042)">
                                    <g id="Path_1" transform="translate(146.6 111.176)">
                                        <path id="Path_267" data-name="Path 267" d="M764.062,298.124v81.108H654.245V271.46H312.532V120.3H146.6v31.124H275.927v469h36.605v-220H1764.58v220.1h26.85v-322.4ZM624.887,358.143H312.532V298.124H624.887Z" transform="translate(-146.6 -120.3)"/>
                                    </g>
                                    <PotTop id="Group_7" data-name="Group 7" transform="translate(177.411 -8.335)">
                                        <g id="Path_2" transform="translate(10.533 56.518)">
                                            <path id="Path_268" data-name="Path 268" d="M261.152,59.84c0,4.088-15.7,7.34-35.026,7.433S191.1,64.02,191.1,59.932s15.7-7.34,35.026-7.433S261.152,55.752,261.152,59.84Z" transform="translate(-191.1 -52.498)" fill="#de7ba1"/>
                                        </g>
                                        <g id="Group_1-2" data-name="Group_1" transform="matrix(-0.656, -0.755, 0.755, -0.656, 45.715, 97.02)">
                                            <g id="Group_6">
                                                <g id="Group_5">
                                                    <g id="Group_1-2-2" data-name="Group_1-2" transform="translate(0.018 0.088)">
                                                        <g id="Path_3">
                                                            <path id="Path_269" data-name="Path 269" d="M0,0S1.022.743,2.694,2.044C4.367,3.438,6.782,5.389,9.569,7.9A119.919,119.919,0,0,1,27.408,28.8a85.512,85.512,0,0,1,7.061,13.193,44.119,44.119,0,0,1,3.345,12.914,18.068,18.068,0,0,1-1.579,9.662,7.914,7.914,0,0,1-1.579,2.323l-.65.743.557-.836a11.4,11.4,0,0,0,1.115-2.416,17.882,17.882,0,0,0,.372-9.1C34.75,47.85,30.2,38.931,24.808,30.756A193.95,193.95,0,0,0,8.269,9.291C5.853,6.5,3.809,4.181,2.323,2.6.836.929,0,0,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_2-2" data-name="Group_2" transform="translate(0 0.049)">
                                                        <g id="Path_4">
                                                            <path id="Path_270" data-name="Path 270" d="M0,0S1.022.743,2.694,2.044c1.765,1.3,4.274,3.066,7.247,5.2s6.5,4.552,10.406,7.061c3.9,2.416,8.083,5.017,12.357,7.247,8.547,4.552,17.745,8.362,25.085,9.1a22.642,22.642,0,0,0,5.017,0,11.055,11.055,0,0,0,3.809-1.022,11.843,11.843,0,0,0,2.323-1.394l.743-.65-.65.743A12.686,12.686,0,0,1,66.893,30.1a17.533,17.533,0,0,1-9.384,2.23A41.123,41.123,0,0,1,44.6,30.009a75.69,75.69,0,0,1-13.564-5.853A120.106,120.106,0,0,1,8.733,8.547C5.946,6.132,3.809,3.9,2.23,2.323.836.929,0,0,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_3" transform="translate(0.034 0.024)">
                                                        <g id="Path_5">
                                                            <path id="Path_271" data-name="Path 271" d="M0,0S4.274,3.159,10.22,8.269c2.973,2.6,6.411,5.667,9.941,9.1s7.247,7.247,10.777,11.149C38,36.327,44.5,44.781,48.312,51.842a29.083,29.083,0,0,1,3.53,9.2,5.133,5.133,0,0,1-.279,2.88,6.521,6.521,0,0,1-.557.836,2.783,2.783,0,0,0,.372-.929,5.332,5.332,0,0,0-.186-2.694c-.557-2.23-2.323-5.11-4.46-8.269a232.911,232.911,0,0,0-18.3-22.112c-6.968-7.8-14.215-15.33-19.6-21.09-2.694-2.88-4.924-5.3-6.5-6.968A30.958,30.958,0,0,1,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_4" transform="translate(0.044)">
                                                        <g id="Path_6">
                                                            <path id="Path_272" data-name="Path 272" d="M0,0S.929.65,2.508,1.858s3.9,2.88,6.689,4.924c5.482,4.088,12.728,9.662,20.161,14.958a190.581,190.581,0,0,0,21,13.564c3.066,1.579,5.667,2.694,7.618,2.88a5.012,5.012,0,0,0,2.323-.279,2.023,2.023,0,0,0,.743-.465l-.65.65a3.813,3.813,0,0,1-2.416.65,24.141,24.141,0,0,1-8.455-1.765c-6.6-2.508-14.586-7.247-22.112-12.542-3.716-2.694-7.34-5.482-10.684-8.269-3.252-2.787-6.318-5.482-8.733-7.8C2.973,3.53,0,0,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>

                                        <g id="Path_3-2" transform="translate(10.44 63.767)">
                                            <path id="Path_273" data-name="Path 273" d="M251.483,116.323H201.87L191,60.393s26.014,7.34,35.026,7.34S261.052,60.3,261.052,60.3Z" transform="translate(-191 -60.3)" fill="#dc2c6f"/>
                                        </g>
                                    </PotTop>

                                    <CatBottomPotTop id="Group_7" data-name="Group 7" transform="translate(177.411 -8.335)">
                                        <g id="Path_2" transform="translate(10.533 56.518)">
                                            <path id="Path_268" data-name="Path 268" d="M261.152,59.84c0,4.088-15.7,7.34-35.026,7.433S191.1,64.02,191.1,59.932s15.7-7.34,35.026-7.433S261.152,55.752,261.152,59.84Z" transform="translate(-191.1 -52.498)" fill="#de7ba1"/>
                                        </g>
                                        <g id="Group_1-2" data-name="Group_1" transform="matrix(-0.656, -0.755, 0.755, -0.656, 45.715, 97.02)">
                                            <g id="Group_6">
                                                <g id="Group_5">
                                                    <g id="Group_1-2-2" data-name="Group_1-2" transform="translate(0.018 0.088)">
                                                        <g id="Path_3">
                                                            <path id="Path_269" data-name="Path 269" d="M0,0S1.022.743,2.694,2.044C4.367,3.438,6.782,5.389,9.569,7.9A119.919,119.919,0,0,1,27.408,28.8a85.512,85.512,0,0,1,7.061,13.193,44.119,44.119,0,0,1,3.345,12.914,18.068,18.068,0,0,1-1.579,9.662,7.914,7.914,0,0,1-1.579,2.323l-.65.743.557-.836a11.4,11.4,0,0,0,1.115-2.416,17.882,17.882,0,0,0,.372-9.1C34.75,47.85,30.2,38.931,24.808,30.756A193.95,193.95,0,0,0,8.269,9.291C5.853,6.5,3.809,4.181,2.323,2.6.836.929,0,0,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_2-2" data-name="Group_2" transform="translate(0 0.049)">
                                                        <g id="Path_4">
                                                            <path id="Path_270" data-name="Path 270" d="M0,0S1.022.743,2.694,2.044c1.765,1.3,4.274,3.066,7.247,5.2s6.5,4.552,10.406,7.061c3.9,2.416,8.083,5.017,12.357,7.247,8.547,4.552,17.745,8.362,25.085,9.1a22.642,22.642,0,0,0,5.017,0,11.055,11.055,0,0,0,3.809-1.022,11.843,11.843,0,0,0,2.323-1.394l.743-.65-.65.743A12.686,12.686,0,0,1,66.893,30.1a17.533,17.533,0,0,1-9.384,2.23A41.123,41.123,0,0,1,44.6,30.009a75.69,75.69,0,0,1-13.564-5.853A120.106,120.106,0,0,1,8.733,8.547C5.946,6.132,3.809,3.9,2.23,2.323.836.929,0,0,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_3" transform="translate(0.034 0.024)">
                                                        <g id="Path_5">
                                                            <path id="Path_271" data-name="Path 271" d="M0,0S4.274,3.159,10.22,8.269c2.973,2.6,6.411,5.667,9.941,9.1s7.247,7.247,10.777,11.149C38,36.327,44.5,44.781,48.312,51.842a29.083,29.083,0,0,1,3.53,9.2,5.133,5.133,0,0,1-.279,2.88,6.521,6.521,0,0,1-.557.836,2.783,2.783,0,0,0,.372-.929,5.332,5.332,0,0,0-.186-2.694c-.557-2.23-2.323-5.11-4.46-8.269a232.911,232.911,0,0,0-18.3-22.112c-6.968-7.8-14.215-15.33-19.6-21.09-2.694-2.88-4.924-5.3-6.5-6.968A30.958,30.958,0,0,1,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_4" transform="translate(0.044)">
                                                        <g id="Path_6">
                                                            <path id="Path_272" data-name="Path 272" d="M0,0S.929.65,2.508,1.858s3.9,2.88,6.689,4.924c5.482,4.088,12.728,9.662,20.161,14.958a190.581,190.581,0,0,0,21,13.564c3.066,1.579,5.667,2.694,7.618,2.88a5.012,5.012,0,0,0,2.323-.279,2.023,2.023,0,0,0,.743-.465l-.65.65a3.813,3.813,0,0,1-2.416.65,24.141,24.141,0,0,1-8.455-1.765c-6.6-2.508-14.586-7.247-22.112-12.542-3.716-2.694-7.34-5.482-10.684-8.269-3.252-2.787-6.318-5.482-8.733-7.8C2.973,3.53,0,0,0,0Z" fill="#7c8d3b"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>

                                        <g id="Path_3-2" transform="translate(10.44 63.767)">
                                            <path id="Path_273" data-name="Path 273" d="M251.483,116.323H201.87L191,60.393s26.014,7.34,35.026,7.34S261.052,60.3,261.052,60.3Z" transform="translate(-191 -60.3)" fill="#dc2c6f"/>
                                        </g>
                                    </CatBottomPotTop>

                                    <g id="Group_8" data-name="Group 8" transform="translate(347.837 438.581)">
                                        <path id="Path_274" data-name="Path 274" d="M402.407,610.3h59.182v34.376H363.2V472.7h39.207Z" transform="translate(-363.2 -472.7)"/>
                                        <path id="Path_275" data-name="Path 275" d="M531.164,601.1c0,16.445,13.75,22.112,23.134,22.112,11.892,0,17.095-2.323,26.014-11.892l26.664,13.38C594.9,644.954,575.3,652.665,551.51,652.665c-32.332,0-58.81-25.457-58.81-56.488,0-31.217,26.479-56.673,58.81-56.673,32.518,0,57.324,19.139,57.324,56.673a43.622,43.622,0,0,1-.186,5.017H531.164Zm41.715-20.44c-1.672-11.242-9.384-17.281-20.254-17.281-12.728,0-19.789,7.525-20.44,17.281Z" transform="translate(-372.385 -477.438)"/>
                                        <path id="Path_276" data-name="Path 276" d="M647.2,542.459h36.884V553.7h.372c8.362-8.362,18.953-14.4,30.659-14.4,14.215,0,29.823,5.2,37.349,19.6,11.242-13.564,23.784-19.6,35.4-19.6,19.6,0,41.715,9.755,41.715,39.578V649.3H792.693V588.82c0-10.591-5.667-19.975-18.767-19.975-11.706,0-16.63,8.547-17.095,16.63v63.734H719.946V588.727c0-10.591-5.667-19.975-18.767-19.975-12.264,0-17.095,9.384-17.095,17.931V649.21H647.2Z" transform="translate(-383.343 -477.424)"/>
                                        <path id="Path_277" data-name="Path 277" d="M935.485,539.4c35.4,0,63.734,23.32,63.734,56.673,0,32.889-26.85,56.488-63.734,56.488-38.557,0-64.385-23.505-64.385-56.488C871.1,562.72,899.437,539.4,935.485,539.4Zm-.186,81.665c14.4,0,24.806-10.406,24.806-24.992,0-14.772-11.056-25.178-24.806-25.178-13.936,0-24.992,10.406-24.992,25.178-.093,14.587,9.941,24.992,24.993,24.992Z" transform="translate(-399.224 -477.431)"/>
                                        <path id="Path_278" data-name="Path 278" d="M1038.4,542.459h36.884V553.7h.372c8.362-8.362,18.953-14.4,30.659-14.4,19.6,0,46.918,9.755,46.918,39.578V649.3h-36.884V588.82c0-10.591-7.34-20.811-19.139-20.811-11.056,0-21.926,10.22-21.926,20.811V649.3H1038.4Z" transform="translate(-411.09 -477.424)"/>
                                        <path id="Path_279" data-name="Path 279" d="M1222.768,660.83c4,6.5,12.914,13.75,28.987,13.75,10.22,0,26.014-6.039,26.014-24.992v-7.9c-7.9,8.733-20.44,10.87-35.026,10.87-31.217,0-53.143-25.178-53.143-56.488s21.926-56.673,53.143-56.673c14.215,0,25.642,4.552,34.376,12.728h.65v-9.569h36.884V652.375c0,35.862-26.479,54.351-62.9,54.351-22.3,0-47.94-5.017-59.182-31.031Zm31.031-39.764c14.4,0,24.806-10.406,24.806-24.992,0-14.772-11.056-25.178-24.806-25.178-13.936,0-24.992,10.406-24.992,25.178,0,14.586,9.941,24.991,24.993,24.991Z" transform="translate(-421.815 -477.431)"/>
                                        <path id="Path_280" data-name="Path 280" d="M1362.293,542.459h36.884v15.423h.372s11.706-18.581,32.7-18.581a44.1,44.1,0,0,1,23.32,6.689l-15.423,31.217s-6.875-5.853-19.139-5.853c-19.139,0-21.926,18.953-21.926,21.647v56.3H1362.2V542.459Z" transform="translate(-434.057 -477.424)"/>
                                        <path id="Path_281" data-name="Path 281" d="M1528.343,539.4c14.4,0,26.293,4.831,35.026,13.379v-10.22h36.884V649.5H1563.74V637.417h-.372a47.438,47.438,0,0,1-35.026,15.237c-31.217,0-53.143-25.178-53.143-56.488S1497.033,539.4,1528.343,539.4Zm11.056,81.665c14.4,0,24.806-10.406,24.806-24.992,0-14.772-11.056-25.178-24.806-25.178-13.936,0-24.992,10.406-24.992,25.178-.093,14.587,9.941,24.992,24.993,24.992Z" transform="translate(-442.071 -477.431)"/>
                                        <path id="Path_282" data-name="Path 282" d="M1728.727,547.669l-12.078,24.156s-12.542-6.689-23.784-6.689c-8.083,0-11.428,1.487-11.428,6.5,0,5.667,7.526,7.34,16.63,10.22,14.215,4.367,32.517,12.264,32.517,34.19,0,31.5-28.8,36.513-50.634,36.513-25.828,0-41.251-14.4-41.251-14.4l15.051-25.178s14.586,12.264,26.479,12.264c5.2,0,11.428-.65,11.428-7.711,0-7.711-11.706-8.362-23.784-14.586-10.87-5.667-21.647-13.1-21.647-29.173,0-21.926,18.581-34.376,46.639-34.376A82.3,82.3,0,0,1,1728.727,547.669Z" transform="translate(-453.668 -477.431)"/>
                                        <path id="Path_283" data-name="Path 283" d="M1850.327,547.669l-12.078,24.156s-12.542-6.689-23.784-6.689c-8.083,0-11.428,1.487-11.428,6.5,0,5.667,7.526,7.34,16.63,10.22,14.215,4.367,32.518,12.264,32.518,34.19,0,31.5-28.8,36.513-50.634,36.513-25.828,0-41.251-14.4-41.251-14.4l15.051-25.178s14.586,12.264,26.479,12.264c5.2,0,11.428-.65,11.428-7.711,0-7.711-11.706-8.362-23.784-14.586-10.87-5.667-21.647-13.1-21.647-29.173,0-21.926,18.582-34.376,46.639-34.376a82.3,82.3,0,0,1,35.861,8.27Z" transform="translate(-462.293 -477.431)"/>
                                    </g>
                                    <g id="Group_9" data-name="Group 9" transform="translate(1601.713 307.396)">
                                        <path id="Path_284" data-name="Path 284" d="M1725.714,376.939l.093-5.11.093-4.088v-1.208a9.046,9.046,0,0,0,.093-1.672.847.847,0,0,0-.279-.465.849.849,0,0,1-.279-.465v-.093a5.523,5.523,0,0,1-1.579.186,12.192,12.192,0,0,1-2.88-.372c-.372-.093-1.3-.186-2.88-.465V373.5c0,1.579.093,3.995.279,7.34.186,3.252.279,5.76.279,7.34a3.851,3.851,0,0,1-.743,2.323,2.259,2.259,0,0,1-1.858,1.022c-1.115,0-1.951-1.115-2.416-3.345a44.637,44.637,0,0,1-.743-8.826l.186-7.247c.093-2.044.093-4.181.093-6.411V363a8.621,8.621,0,0,0-.093-1.858v-.465l.279-5.2c0-2.23-.093-5.667-.279-10.127s-.279-7.8-.279-10.127a2.764,2.764,0,0,1,.743-1.858,2.288,2.288,0,0,1,1.765-.836c1.951,0,2.88,1.765,2.88,5.389L1718,348.974c-.093,3.345-.186,7.061-.186,11.149a18.355,18.355,0,0,0,4.831.465h3.345a4.662,4.662,0,0,0,.186-1.115c0-2.88-.186-7.061-.557-12.728-.372-5.2-.65-9.477-.65-12.821a2.326,2.326,0,0,1,.743-1.672,2.531,2.531,0,0,1,1.858-.65,6.185,6.185,0,0,1,1.765.465l.836.372c0,8.455.279,19.789.929,33.911l.836,22.576a2.224,2.224,0,0,1-.929,1.951,3.782,3.782,0,0,1-2.23.743C1726.736,391.711,1725.714,386.787,1725.714,376.939Z" transform="translate(-1712.8 -331.507)" fill="#fff"/>
                                        <path id="Path_285" data-name="Path 285" d="M1754.14,391.9a11.61,11.61,0,0,1-3.252-4,35.781,35.781,0,0,1-3.252-11.892,86.8,86.8,0,0,1-.836-11.706,9.581,9.581,0,0,1,.093-1.765v-1.579c0-.279,0-.465-.093-.465v-1.579l.093-6.225c.093-1.951.279-4.924.743-9.1a30.823,30.823,0,0,1,2.044-8.269,5.762,5.762,0,0,1,1.579-2.694,3.019,3.019,0,0,1,2.23-.929,15.477,15.477,0,0,1,6.411,1.3,12.527,12.527,0,0,1,4.831,3.623,20.566,20.566,0,0,1,3.066,5.482,31.879,31.879,0,0,1,1.765,6.968,53.926,53.926,0,0,1,.557,7.99v.743c0,1.858,0,3.53.093,5.017l.093,5.11c0,17.188-4,25.735-11.985,25.735a9.208,9.208,0,0,1-4.18-1.77Zm6.875-3.53a7.729,7.729,0,0,0,2.044-3.53,33.763,33.763,0,0,0,2.044-9.848c.279-3.066.465-5.667.465-7.9a102.531,102.531,0,0,0-.65-10.591q-.558-6.829-.557-10.313v-1.579a9.332,9.332,0,0,0-2.323-5.946,6.507,6.507,0,0,0-5.11-2.509c-2.044,0-3.345,1.208-4,3.53a25.237,25.237,0,0,0-.929,7.154,27.062,27.062,0,0,0,.093,2.88v2.416l-.186,4.088c-.093,1.208-.186,2.6-.186,4.088,0,3.252.093,6.411.279,9.569a84.328,84.328,0,0,0,1.765,13.007c.929,4.367,2.323,6.6,4.181,6.6a3.557,3.557,0,0,0,3.07-1.113Z" transform="translate(-1715.211 -331.514)" fill="#fff"/>
                                        <path id="Path_286" data-name="Path 286" d="M1784.6,391.8a3.294,3.294,0,0,1-1.3-2.323l1.022-1.487a4.972,4.972,0,0,1,1.672.372,11.349,11.349,0,0,0,2.044.279,3.738,3.738,0,0,0,2.88-1.394,10.059,10.059,0,0,0,1.951-3.716,30.851,30.851,0,0,0,1.115-7.711,25.1,25.1,0,0,0-3.716-12.45l-1.022-1.672c-1.115-1.951-2.044-3.716-2.88-5.3a40.148,40.148,0,0,1-1.951-5.017,22.749,22.749,0,0,1-1.115-6.968,21.387,21.387,0,0,1,1.765-8.269,8.378,8.378,0,0,1,2.508-3.345,6.029,6.029,0,0,1,3.716-1.3,5,5,0,0,1,2.973,1.022,2.914,2.914,0,0,1,1.394,2.416,1.26,1.26,0,0,1-.65,1.208,2.571,2.571,0,0,1-1.486.465,3.349,3.349,0,0,1-1.858-.557c-1.3.093-2.323,1.208-3.159,3.53a18.168,18.168,0,0,0-1.022,5.2,16.924,16.924,0,0,0,.836,5.2,22.08,22.08,0,0,0,2.044,4.738l1.951,3.53,3.159,5.946a28.673,28.673,0,0,1,2.044,5.853,32.789,32.789,0,0,1,.743,6.689,22.9,22.9,0,0,1-2.508,10.406,10.491,10.491,0,0,1-3.531,3.995,8.611,8.611,0,0,1-5.017,1.487v.279a6,6,0,0,1-2.6-1.106Z" transform="translate(-1717.8 -331.5)" fill="#fff"/>
                                        <path id="Path_287" data-name="Path 287" d="M1817.42,390.267l-.093-3.53c-.093-1.022-.093-2.137-.093-3.438l.093-3.066c-.186-.65-.65-7.154-1.3-19.6-.743-12.357-1.115-20.347-1.208-23.877-3.438-.093-5.667-.465-6.6-1.115a2.069,2.069,0,0,1-1.022-1.579,1.545,1.545,0,0,1,.929-1.3,3.9,3.9,0,0,1,1.951-.557,50.927,50.927,0,0,1,6.039.372,50.337,50.337,0,0,0,6.039.465h1.487c1.858,0,2.787.743,2.787,2.137a2.012,2.012,0,0,1-.929,1.765,3.166,3.166,0,0,1-2.044.65,13.817,13.817,0,0,1-2.323-.279l-1.765-.372-.093.465-.093.465c0,5.3.464,13.75,1.486,25.364q1.394,16.3,1.394,25.364a2.285,2.285,0,0,1-.929,1.858,3.1,3.1,0,0,1-2.137.836Z" transform="translate(-1719.495 -331.55)" fill="#fff"/>
                                        <path id="Path_288" data-name="Path 288" d="M1842.679,392.182a2.894,2.894,0,0,1-1.022-1.951v-4.088a11.86,11.86,0,0,1-.093-1.858c0-1.208-.093-3.159-.279-5.76l-.186-5.76c0-2.88.093-7.247.279-13.007.279-5.2.372-9.569.372-13.007a36.558,36.558,0,0,0-.279-3.716c-.186-1.765-.279-2.973-.279-3.716,0-4.274,1.208-6.318,3.716-6.318a22.816,22.816,0,0,1,6.875,1.208q3.9,1.115,3.9,2.787a2.693,2.693,0,0,1-.743,1.672,5.367,5.367,0,0,1-1.858,1.208,31.909,31.909,0,0,0-6.782-1.765c.093.929.093,2.137.093,3.809,0,2.416,0,4.552-.093,6.411q-.279,4.32-.279,6.411l.279,1.394,1.394-.093a10.115,10.115,0,0,1,1.765-.093c2.88,0,4.274.836,4.274,2.509a2.11,2.11,0,0,1-.836,1.672,3,3,0,0,1-2.044.65,15.355,15.355,0,0,1-2.508-.186h-2.23c.093,1.579.186,3.809.186,6.782s0,5.667-.093,8.083c-.186,3.623-.279,6.318-.279,8.176a29.222,29.222,0,0,0,.186,5.017l2.973-.093c1.022-.093,2.23-.093,3.623-.093,2.23,0,3.438.743,3.438,2.137a2.183,2.183,0,0,1-1.115,1.951,5.041,5.041,0,0,1-2.694.65h-7.618A7.023,7.023,0,0,1,1842.679,392.182Z" transform="translate(-1721.9 -331.606)" fill="#fff"/>
                                        <path id="Path_289" data-name="Path 289" d="M1876.967,392.161a5.933,5.933,0,0,1-3.066.929,2.284,2.284,0,0,1-1.858-.929,3.454,3.454,0,0,1-.743-2.044c0-5.017.186-11.706.65-19.975.372-8.919.557-15.516.557-19.975v-.65c-.372-3.716-.557-6.6-.557-8.362,0-5.667,1.022-8.455,3.159-8.455a2.176,2.176,0,0,1,1.579.65,2.038,2.038,0,0,1,.65,1.579,32.535,32.535,0,0,1-.279,3.438,29.278,29.278,0,0,0-.279,3.438c0,3.995,0,7.618.093,10.777s.093,6.689.093,10.684a12.958,12.958,0,0,0-.465,3.716V368.1l-.186,7.061c-.093,2.044-.186,4.46-.186,7.154,0,4.088,1.022,6.132,2.973,6.132h1.672a13.128,13.128,0,0,1,2.044-.093c3.252,0,4.831.929,4.831,2.694a1.785,1.785,0,0,1-.836,1.487,6.2,6.2,0,0,1-1.951.836,21.716,21.716,0,0,0-7.895-1.21Z" transform="translate(-1724.042 -331.585)" fill="#fff"/>
                                    </g>
                                </g>
                            </g>

                            <PotCat id="Group_7-2" data-name="Group 7" transform="translate(49.274 571.533) rotate(-141)">
                                <g id="Path_2-2" data-name="Path_2" transform="translate(10.533 56.518)">
                                    <path id="Path_268-2" data-name="Path 268" d="M261.152,59.84c0,4.088-15.7,7.34-35.026,7.433S191.1,64.02,191.1,59.932s15.7-7.34,35.026-7.433S261.152,55.752,261.152,59.84Z" transform="translate(-191.1 -52.498)" fill="#de7ba1"/>
                                </g>
                                <g id="Group_1-3" data-name="Group_1" transform="matrix(-0.656, -0.755, 0.755, -0.656, 45.715, 97.02)">
                                    <g id="Group_6-2" data-name="Group_6">
                                        <g id="Group_5-2" data-name="Group_5">
                                            <g id="Group_1-2-3" data-name="Group_1-2" transform="translate(0.018 0.088)">
                                                <g id="Path_3-2-2" data-name="Path_3">
                                                    <path id="Path_269-2" data-name="Path 269" d="M0,0S1.022.743,2.694,2.044C4.367,3.438,6.782,5.389,9.569,7.9A119.919,119.919,0,0,1,27.408,28.8a85.512,85.512,0,0,1,7.061,13.193,44.119,44.119,0,0,1,3.345,12.914,18.068,18.068,0,0,1-1.579,9.662,7.914,7.914,0,0,1-1.579,2.323l-.65.743.557-.836a11.4,11.4,0,0,0,1.115-2.416,17.882,17.882,0,0,0,.372-9.1c-1.3-7.433-5.85-16.352-11.242-24.527A193.946,193.946,0,0,0,8.269,9.291C5.853,6.5,3.809,4.181,2.323,2.6.836.929,0,0,0,0Z" fill="#7c8d3b"/>
                                                </g>
                                            </g>
                                            <g id="Group_2-3" data-name="Group_2" transform="translate(0 0.049)">
                                                <g id="Path_4-2" data-name="Path_4">
                                                    <path id="Path_270-2" data-name="Path 270" d="M0,0S1.022.743,2.694,2.044c1.765,1.3,4.274,3.066,7.247,5.2s6.5,4.552,10.406,7.061c3.9,2.416,8.083,5.017,12.357,7.247,8.547,4.552,17.745,8.362,25.085,9.1a22.642,22.642,0,0,0,5.017,0,11.055,11.055,0,0,0,3.809-1.022,11.843,11.843,0,0,0,2.323-1.394l.743-.65-.65.743A12.686,12.686,0,0,1,66.893,30.1a17.533,17.533,0,0,1-9.384,2.23A41.123,41.123,0,0,1,44.6,30.009a75.69,75.69,0,0,1-13.564-5.853A120.106,120.106,0,0,1,8.733,8.547C5.946,6.132,3.809,3.9,2.23,2.323.836.929,0,0,0,0Z" fill="#7c8d3b"/>
                                                </g>
                                            </g>
                                            <g id="Group_3-2" data-name="Group_3" transform="translate(0.034 0.024)">
                                                <g id="Path_5-2" data-name="Path_5">
                                                    <path id="Path_271-2" data-name="Path 271" d="M0,0S4.274,3.159,10.22,8.269c2.973,2.6,6.411,5.667,9.941,9.1s7.247,7.247,10.777,11.149C38,36.327,44.5,44.781,48.312,51.842a29.083,29.083,0,0,1,3.53,9.2,5.133,5.133,0,0,1-.279,2.88,6.521,6.521,0,0,1-.557.836,2.783,2.783,0,0,0,.372-.929,5.332,5.332,0,0,0-.186-2.694c-.557-2.23-2.323-5.11-4.46-8.269a232.909,232.909,0,0,0-18.3-22.112c-6.968-7.8-14.215-15.33-19.6-21.09-2.694-2.88-4.924-5.3-6.5-6.968A30.958,30.958,0,0,1,0,0Z" fill="#7c8d3b"/>
                                                </g>
                                            </g>
                                            <g id="Group_4-2" data-name="Group_4" transform="translate(0.044)">
                                                <g id="Path_6-2" data-name="Path_6">
                                                    <path id="Path_272-2" data-name="Path 272" d="M0,0S.929.65,2.508,1.858s3.9,2.88,6.689,4.924c5.482,4.088,12.728,9.662,20.161,14.958a190.581,190.581,0,0,0,21,13.564c3.066,1.579,5.667,2.694,7.618,2.88a5.012,5.012,0,0,0,2.323-.279,2.023,2.023,0,0,0,.743-.465l-.65.65a3.813,3.813,0,0,1-2.416.65,24.141,24.141,0,0,1-8.455-1.765c-6.6-2.508-14.586-7.247-22.112-12.542-3.716-2.694-7.34-5.482-10.684-8.269-3.252-2.787-6.318-5.482-8.733-7.8C2.973,3.53,0,0,0,0Z" fill="#7c8d3b"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>

                                <g id="Path_3-2-3" data-name="Path_3-2" transform="translate(10.44 63.767)">
                                    <path id="Path_273-2" data-name="Path 273" d="M251.483,116.323H201.87L191,60.393s26.014,7.34,35.026,7.34S261.052,60.3,261.052,60.3Z" transform="translate(-191 -60.3)" fill="#dc2c6f"/>
                                </g>
                            </PotCat>

                            <PotCat id="_06" data-name="06" transform="translate(-32.84 18.576)">
                                <g id="Objects" transform="translate(144.925 198.867)">
                                    <path id="Path_265" data-name="Path 265" d="M221.64,123.72c-3.283-5.125-11.044-8.251-19.24-4.423-9.687,4.524-9.374,16.781-6.879,25.629s12.86,21.817,14.58,30.471c1.187,5.971-2.628,14.971-9.618,21.009a95.113,95.113,0,0,0-1.664-22.282c-2.888-15.678-8.767-22.743-15.678-26.921s-13.482-2.854-18.337-4.088-4.716-9.923-7.565-16.408c-2.732-6.217-5.993-8.351-8.587-10.306s-3.559-2.824-4.757-3.791-8.2-3.752-8.7-3.713,8.974,3.558,8.7,3.713.464.387,0,0-8.671-4.332-8.942-3.713,2.1,5.415,1.786,6-.7.58-2.862,1.2-5.338,4.1-6.189,5.222-1.625,4.719-2.089,5.492a15.7,15.7,0,0,1-1.934,2.321,3.193,3.193,0,0,0-.812,2.011,2,2,0,0,0,.735,1.663c.348.271.039.967.387,1.586a3.818,3.818,0,0,0,1.16,1.2c.309.232-.193.774.89,2.011s5.9,1.457,7.194,2.8,1.031,4.642.98,6.447-2.321,5.776-2.527,9.18,4.126,12.893,6.5,15.42,5.931,10.417,5.879,12.171a51.5,51.5,0,0,1-1.5,7.684c-.516,2.063-.929,7.633-1.393,8.1s-3.3.928-4.229,1.7-1.083,2.321-.1,4.023a3.008,3.008,0,0,0,2.888,1.444,5.1,5.1,0,0,0-1.392,1.7c-.464,1.006-.232,2.785.851,3.327s6.343.928,7.968.774a4.908,4.908,0,0,0,3.636-1.934c1.238-1.315,1.47-4.41,1.779-4.719s2.321.619,4.1.7,6.343.309,6.343.309l.928.774a6.564,6.564,0,0,0-2.708,3.4c-.464,2.011.464,3.352,2.424,4.074s5.879-.206,7.117-.722,2.475.31,5.467.31,9.8-1.238,14.234-1.444,3.094-.722,4.023-1.547,1.65-.516,4.332-2.785a5.627,5.627,0,0,0,1.532-2.409q1.255-.556,2.5-1.216c11.2-5.945,25.162-24.086,4.611-52.448-5.7-7.865-7.4-18.189-5.072-23.075a8.509,8.509,0,0,1,13.239-2.51c4.453,3.7,2.8,8.378,1.327,9.685s-8.183,2.051-5.666,6.986c1.282,2.515,7.346.939,10.673-3.781S224.922,128.845,221.64,123.72Z" transform="translate(-122.851 -108.833)"/>
                                </g>
                            </PotCat>

                            <CatBottomPotTop id="_06-2" data-name="06" transform="translate(1524.16 198.576)">
                                <g id="Objects-2" data-name="Objects" transform="translate(144.925 198.867)">
                                    <path id="Path_265-2" data-name="Path 265" d="M221.64,123.72c-3.283-5.125-11.044-8.251-19.24-4.423-9.687,4.524-9.374,16.781-6.879,25.629s12.86,21.817,14.58,30.471c1.187,5.971-2.628,14.971-9.618,21.009a95.113,95.113,0,0,0-1.664-22.282c-2.888-15.678-8.767-22.743-15.678-26.921s-13.482-2.854-18.337-4.088-4.716-9.923-7.565-16.408c-2.732-6.217-5.993-8.351-8.587-10.306s-3.559-2.824-4.757-3.791-8.2-3.752-8.7-3.713,8.974,3.558,8.7,3.713.464.387,0,0-8.671-4.332-8.942-3.713,2.1,5.415,1.786,6-.7.58-2.862,1.2-5.338,4.1-6.189,5.222-1.625,4.719-2.089,5.492a15.7,15.7,0,0,1-1.934,2.321,3.193,3.193,0,0,0-.812,2.011,2,2,0,0,0,.735,1.663c.348.271.039.967.387,1.586a3.818,3.818,0,0,0,1.16,1.2c.309.232-.193.774.89,2.011s5.9,1.457,7.194,2.8,1.031,4.642.98,6.447-2.321,5.776-2.527,9.18,4.126,12.893,6.5,15.42,5.931,10.417,5.879,12.171a51.5,51.5,0,0,1-1.5,7.684c-.516,2.063-.929,7.633-1.393,8.1s-3.3.928-4.229,1.7-1.083,2.321-.1,4.023a3.008,3.008,0,0,0,2.888,1.444,5.1,5.1,0,0,0-1.392,1.7c-.464,1.006-.232,2.785.851,3.327s6.343.928,7.968.774a4.908,4.908,0,0,0,3.636-1.934c1.238-1.315,1.47-4.41,1.779-4.719s2.321.619,4.1.7,6.343.309,6.343.309l.928.774a6.564,6.564,0,0,0-2.708,3.4c-.464,2.011.464,3.352,2.424,4.074s5.879-.206,7.117-.722,2.475.31,5.467.31,9.8-1.238,14.234-1.444,3.094-.722,4.023-1.547,1.65-.516,4.332-2.785a5.627,5.627,0,0,0,1.532-2.409q1.255-.556,2.5-1.216c11.2-5.945,25.162-24.086,4.611-52.448-5.7-7.865-7.4-18.189-5.072-23.075a8.509,8.509,0,0,1,13.239-2.51c4.453,3.7,2.8,8.378,1.327,9.685s-8.183,2.051-5.666,6.986c1.282,2.515,7.346.939,10.673-3.781S224.922,128.845,221.64,123.72Z" transform="translate(-122.851 -108.833)"/>
                                </g>
                            </CatBottomPotTop>
                        </g>
                    </g>
                </g>
            </svg>
        </Root>
    );
};

export default Logo;

const opacity = keyframes`
from {
    visibility: visible;
}
33.33333% {
    visibility: hidden;
}
to {
    visibility: hidden;
}`;

const Root = styled.div`
    width: 60%;
    margin-left: -10%;
    z-index: 10;
    -webkit-backface-visibility: hidden;
    -webkit-transform:translate3d(0,0,0);

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 95%;
        margin-left: -5%;
    }

    svg {
        -webkit-transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
    }
`;

const PotTop = styled.g`
    animation: ${ opacity } 3s linear infinite;
    visibility: hidden;
`;

const CatBottomPotTop = styled.g`
    visibility: hidden;
    animation: ${ opacity } 3s linear infinite 1s;
`;

const PotCat = styled.g`
    visibility: hidden;
    animation: ${ opacity } 3s linear infinite 2s;
`;
